a {
  color: inherit;
  text-decoration: none;
}

figure div img {
  width: 100% !important;
}

.hideLogo {
  opacity: 0;
  -webkit-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
}

.showLogo {
  opacity: 1;
}

.hideTitle {
  opacity: 0;
}

.showTitle {
  opacity: 1;
  -webkit-animation: fadein 1s;
  animation: fadein 1s;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}